<template>
  <section
    v-if="officerStatus.officer && disabled === false && disabledSingleStateCountry === false"
    class="container camp-select mx-lg"
  >
    <div class="d-flex align-items-center flex-wrap flex-sm-nowrap justify-content-start justify-content-sm-between">
      <h4 class="mb-4 mb-sm-0 w-100-sd">
        {{ selectedCampName }}
        <span v-if="tooltip" class="i-tooltip" v-b-tooltip.hover :title="tooltip">
          <i-tooltip />
        </span>
      </h4>

      <div v-if="displayOnly === false">
        <form class="filters d-flex justify-content-start justify-content-sm-end">
          <b-form-select
            v-if="officerStatus.zone_officer && properZones.length > 1"
            v-model="zone"
            :options="properZones"
            :plain="true"
            @change="handleZoneChange($event)"
            class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0 regionzone"
          >
            <template slot="first">
              <option :value="null" disabled>{{ i18n['camp-select'].tcSelectRegionOrZone }}</option>
            </template>
          </b-form-select>

          <b-form-select
            v-if="(officerStatus.zone_officer || officerStatus.state_officer) && (states.length > 1 || !showCamp)"
            v-model="state"
            :disabled="state === null && states.length === 0"
            :options="states"
            :plain="true"
            @change="handleStateChange($event)"
            class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0 countrystate"
          >
            <template slot="first">
              <option :value="null" disabled>{{ i18n['camp-select'].tcSelectCountryOrState }}</option>
            </template>
          </b-form-select>

          <b-form-select
            v-if="showCamp"
            v-model="camp"
            :disabled="camp === null && camps.length === 0"
            :options="camps"
            :plain="true"
            @change="handleCampChange($event)"
            class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0"
          >
            <template slot="first">
              <option :value="null" disabled>{{ i18n['camp-select'].tcSelectCamp }}</option>
            </template>
          </b-form-select>
          <div @click="handleGoClick" class="button-div">{{ i18n['camp-select'].tcGo }}</div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import Gbutton from '@/components/Gbutton.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'

export default {
  name: 'camp-select',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'camp-select': {
            tcGo: 'Go',
            tcSelectCamp: 'Select Camp',
            tcSelectCountryOrState: 'Select Country/State',
            tcSelectRegionOrZone: 'Select Region/Zone'
          }
        }
      }
    },
    tooltip: String,
    showCamp: {
      type: Boolean,
      default: true
    },
    onlyUSA: {
      type: Boolean,
      default: false
    },
    nonUSA: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Gbutton: Gbutton,
    iTooltip: iTooltip
  },
  data() {
    return {
      selectedCampName: '',
      zone: null,
      state: null,
      camp: null,
      disabledSingleStateCountry: false,
      fortified_page_arrays: [],
      page_arrays: [
        //put URLS here if you don't want the officer toolbar to redirect
        [
          '/officers/camp/goal-planner/st',
          '/officers/state/leadership-management-team',
          '/officers/country/camp-visitation',
          '/officers/country/leadership-management-team',
          '/officers/directory/members-list',
          '/officers/directory/country/members-list',
          '/calendar/state/event/state-event-view',
          '/programs/pw/conversations',
          '/programs/pw/conversations/instructors',
          '/programs/pw/conversations/educational-units',
          '/programs/pw/conversations/list',
          '/programs/pw/conversations/instructor-history',
          '/programs/pw/conversations/educational-unit-history'
        ],
        [
          '/officers/page/country-landing-page',
          '/officers/state/country-directory',
          '/officers/directory/country/camp-officer-selections',
          '/officers/directory/country/directory-camp',
          '/officers/directory/country/membership-dinner-speakers',
          '/officers/directory/country/pastor-banquet-speakers',
          '/officers/directory/country/qualified-speakers'
        ],
        [
          '/officers/page/state-landing-page',
          '/officers/state/state-directory',
          '/officers/directory/scripture-blitz-speakers',
          '/officers/directory/membership-dinner-speakers',
          '/officers/directory/pastor-banquet-speakers',
          '/officers/directory/directory-camp',
          '/officers/directory/widows-list'
        ],
        [
          '/officers/page/country-landing-page',
          '/officers/state/country-directory',
          '/officers/directory/country/directory-camp',
          '/officers/directory/country/membership-dinner-speakers',
          '/officers/directory/country/pastor-banquet-speakers',
          '/officers/country/directory/members-list',
          '/officers/directory/country/camp-officer-selections',
          '/officers/directory/country/qualified-speakers'
        ],
        [
          '/officers/state/state-directory',
          '/reports/report-center/st',
          '/reports/report-parameters',
          '/officers/directory/members-list',
          '/officers/state/leadership-management-team'
        ]
      ]
    }
  },
  async created() {
    this.fortify_page_arrays()
    await Promise.all([
      await this.saveOfficerToolbarCampName(this.domainChange ? this.demograph.camp_name : null),
      await this.setOfficerToolbar(),
      await this.revertOfficerToolbarChanges(this.domainChange)
    ]).then(() => {
      this.$emit('camp_changed', 'created')
    })
  },
  methods: {
    ...mapActions({
      appendOfficerToolbarCountriesStates: 'user/appendOfficerToolbarCountriesStates',
      getOfficerToolbarCamps: 'user/getOfficerToolbarCamps',
      getOfficerToolbarCountriesStates: 'user/getOfficerToolbarCountriesStates',
      getOfficerToolbarRegionsZones: 'user/getOfficerToolbarRegionsZones',
      resetOfficerToolbarDomainChange: 'user/resetOfficerToolbarDomainChange',
      revertOfficerToolbarChanges: 'user/revertOfficerToolbarChanges',
      saveOfficerToolbarCampName: 'user/saveOfficerToolbarCampName',
      saveOfficerToolbarCampsSelection: 'user/saveOfficerToolbarCampsSelection',
      saveOfficerToolbarCountriesStatesSelection: 'user/saveOfficerToolbarCountriesStatesSelection',
      saveOfficerToolbarRegionsZonesSelection: 'user/saveOfficerToolbarRegionsZonesSelection',
      setCampFromOfficerToolbar: 'user/setCampFromOfficerToolbar',
      setOfficerToolbarStatus: 'user/setOfficerToolbarStatus',
      setOfficerToolbarUser: 'user/setOfficerToolbarUser',
      setOTBSnapshot: 'user/setOTBSnapshot',
      setSearchTerm: 'search/setSearchTerm'
    }),
    fortify_page_arrays() {
      this.fortified_page_arrays = this.page_arrays.map(ma => {
        let inner_array = []
        ma.forEach(pb => {
          inner_array.push(pb)
          inner_array.push(pb + '/')
          inner_array.push(pb + '/menu')
        })
        return inner_array
      })
    },
    async handleZoneChange(evt) {
      this.state = null
      this.camp = null
      await this.saveOfficerToolbarRegionsZonesSelection(evt)
      await this.getOfficerToolbarCountriesStates({ zone: evt, SPSP: this.officerToolbarSelected.SPSP })
    },
    async handleStateChange(evt) {
      this.camp = null
      await this.saveOfficerToolbarCountriesStatesSelection(evt)
      await this.getOfficerToolbarCamps(evt)
    },
    async handleGoClick() {
      await this.setSearchTerm(null)
      await this.resetOfficerToolbarDomainChange(false)
      await this.setCampFromOfficerToolbar({
        statekey: this.state || this.officerToolbarSelected.country_state,
        campkey: this.camp
      })
      await this.saveOfficerToolbarCampName(
        this.camps.length > 0 && !!this.camp
          ? (this.camps.find(cf => cf.value === this.camp) === undefined
              ? ''
              : this.camps.find(cf => cf.value === this.camp)
            ).text
          : ''
      )
      await this.setOfficerToolbarStatus(this.userStateName)
      let the_camp_changed = false
      let selectedCamp = this.showCamp
        ? this.officerToolbarSelected.camp_name
        : this.states.find(sf => sf.value === this.officerToolbarSelected.country_state).text
      if (!!selectedCamp) {
        this.selectedCampName = selectedCamp
        the_camp_changed = true
      }
      // take a snapshot of the OTB to be used when going to the Security Mgr page
      await this.setOTBSnapshot({
        key: this.officerToolbarSelected.country_state,
        name: this.officerToolbarSelected.country_state_name
      })
      if ((!!this.officerStatus.zone_officer || !!this.officerStatus.state_officer) && this.showCamp === false) {
        if (this.fortified_page_arrays[0].includes(this.$router.history.current.path)) {
          the_camp_changed = true
        } else if (!!this.currentValue && this.currentValue.text.split(' ')[0] !== 'USA') {
          if (this.fortified_page_arrays[1].includes(this.$router.history.current.path)) {
            the_camp_changed = true
          } else {
            if (
              this.$router.history.current.path.inc === '/officers/state/state-directory' ||
              this.$router.history.current.path.inc === '/officers/state/state-directory/menu'
            )
              this.$router.push({ path: '/officers/state/country-directory' }).catch(() => {})
            else this.$router.push({ path: '/officers/page/country-landing-page' }).catch(() => {})
          }
        } else if (!!this.currentValue && this.currentValue.text.split(' ')[0] === 'USA') {
          if (this.fortified_page_arrays[2].includes(this.$router.history.current.path)) {
            the_camp_changed = true
          } else {
            if (
              this.$router.history.current.path === '/officers/state/country-directory' ||
              this.$router.history.current.path === '/officers/state/country-directory/menu'
            )
              this.$router.push({ path: '/officers/state/state-directory' }).catch(() => {})
            else 
            {
              this.$router.push({ path: '/officers/page/state-landing-page' }).catch(() => {})
            }
          }
        } else {
          if (this.fortified_page_arrays[3].includes(this.$router.history.current.path)) {
            the_camp_changed = true
          } else 
            this.$router.push({ path: '/officers/page/country-landing-page' }).catch(() => {})
        }
      }
      if (the_camp_changed) {
        this.$emit('camp_changed')
      }
    },
    async handleCampChange(evt) {
      await this.saveOfficerToolbarCampsSelection(evt)
    },
    async setOfficerToolbar() {
      let isSPSPChanged = false
      let isSPSP =
        this.fortified_page_arrays[4].includes(this.$router.history.current.path) &&
        this.userLogin.cpositions.filter(m => m.office_acronym === 'SP' || m.office_acronym === 'ASP').length > 0
      if (isSPSP !== this.officerToolbarSelected.SPSP) {
        this.officerToolbarSelected.SPSP = isSPSP
        isSPSPChanged = true
      }
      if ((!!this.officerToolbarSelected.region_zone && !this.zone) || isSPSPChanged || this.domainChange) {
        if (this.userIndKey != this.officerToolbarSelected.userid || isSPSPChanged || this.domainChange) {
          await this.getOfficerToolbarRegionsZones(this.officerToolbarSelected.SPSP)
        }
        let zonechk = this.properZones.filter(zone => zone.value === this.officerToolbarSelected.region_zone)
        if (zonechk.length > 0) {
          this.zone = this.officerToolbarSelected.region_zone
        } else {
          this.zone = null
        }
        if ((!!this.officerToolbarSelected.country_state && !this.state) || isSPSPChanged || this.domainChange) {
          if (this.userIndKey != this.officerToolbarSelected.userid || isSPSPChanged || this.domainChange) {
            await this.getOfficerToolbarCountriesStates({
              zone: this.officerToolbarSelected.region_zone,
              SPSP: this.officerToolbarSelected.SPSP
            })
          }
          let statechk = this.states.filter(state => state.value === this.officerToolbarSelected.country_state)
          if (statechk.length === 0) {
            let usrState = {
              org_name: !!this.userStateName ? `${this.userStateName}` : `${this.demograph.country_name}`,
              org_key: `${this.officerToolbarSelected.country_state}`
            }
            await this.appendOfficerToolbarCountriesStates(usrState)
          }
          this.state = this.officerToolbarSelected.country_state
          if ((!!this.officerToolbarSelected.camp && !this.camp) || isSPSPChanged || this.domainChange) {
            if (this.userIndKey != this.officerToolbarSelected.userid || isSPSPChanged || this.domainChange) {
              await this.getOfficerToolbarCamps(this.officerToolbarSelected.country_state)
            }
            let campchk = this.camps.filter(camp => camp.value === this.officerToolbarSelected.camp)
            if (campchk.length > 0 || !this.showCamp) {
              this.camp = this.showCamp ? this.officerToolbarSelected.camp : this.officerToolbarSelected.country_state
            } else {
              this.camp = null
            }
            let val = this.states.find(sf => sf.value === this.officerToolbarSelected.country_state)
            let selectedCamp = this.showCamp ? this.officerToolbarSelected.camp_name : !val ? '' : val.text
            this.selectedCampName = !!selectedCamp ? selectedCamp : ''
          }
        }
      }
      if (this.properZones.length === 0 && this.states.length <= 1 && !this.showCamp) {
        this.disabledSingleStateCountry = true
      }
      await this.setOfficerToolbarUser(this.userIndKey)
    }
  },
  computed: {
    ...mapGetters({
      camps: 'user/officerToolbarCamps',
      demograph: 'user/demograph',
      domainChange: 'user/domainChange',
      nonusazones: 'user/officerToolbarNonUSAZones',
      officerStatus: 'home/officerStatus',
      officerToolbarSelected: 'user/officerToolbarSelected',
      states: 'user/officerToolbarCountriesStates',
      usazones: 'user/officerToolbarUSAZones',
      userIndKey: 'user/userId',
      userLogin: 'user/userLogin',
      userStateName: 'user/userStateName',
      userStateKey: 'user/userStateKey',
      zones: 'user/officerToolbarRegionsZones'
    }),
    properZones() {
      return this.onlyUSA ? this.usazones : this.nonUSA ? this.nonusazones : this.zones
    },
    currentValue() {
      return this.properZones.find(option => option.value === this.zone)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/settings.scss';

.button-div {
  background-color: #003946;
  padding: 12px 22px;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 22px;
  text-transform: uppercase;
}

.countrystate,
.regionzone {
  max-width: 270px !important;
}
.camp-select {
  h4 {
    display: flex;
    align-items: flex-start;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
    }
  }
  .filters {
    align-items: center;
    @include breakpoint(sm) {
      flex-wrap: wrap;
      max-width: 500px;
    }
    .btn {
      padding: 12px 22px;
      @include breakpoint(sm) {
        margin-right: 100px;
        padding: 18px 28px;
        font-size: 20px;
      }
    }
  }
  .church-select {
    max-width: 201px;
    @include breakpoint(sm) {
      max-width: 320px;
      width: 100%;
    }
  }
}
</style>
